var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.screenWidth > 780)?_c('footer',{staticClass:"bg-f7fafd",staticStyle:{"border-top":"1px solid #d8ebfd","text-align":"center","padding-top":"14px","padding-bottom":"1px","background":"#4b6f8a"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('back-to-top',{attrs:{"bottom":"50px","right":"50px"}},[_c('div',{staticClass:"go-top"},[_c('feather',{attrs:{"type":"arrow-up"}})],1)])],1):_c('footer',{staticClass:"bg-f7fafd",staticStyle:{"border-top":"1px solid #d8ebfd","text-align":"center","padding-top":"14px","padding-bottom":"1px","background":"#4b6f8a"}},[_c('p',{staticStyle:{"color":"white"}},[_vm._v("Công Ty TNHH Reboost. Mã số thuế: 0110542943")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('back-to-top',{attrs:{"bottom":"50px","right":"50px"}},[_c('div',{staticClass:"go-top"},[_c('feather',{attrs:{"type":"arrow-up"}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"white"}},[_vm._v("Công Ty TNHH Reboost | Mã số doanh nghiệp: 0110542943 | Email: "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"href":"mailto:support@reboost.vn"}},[_vm._v("support@reboost.vn")]),_vm._v(" | Zalo: "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"target":"_blank","href":"https://zalo.me/+18135700346"}},[_vm._v("+18135700346")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"white"}},[_vm._v("© 2024 - Bản quyền thuộc về Công ty TNHH Reboost | "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"href":"/terms"}},[_vm._v("Điều Khoản Dịch Vụ")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"href":"/privacy"}},[_vm._v("Chính Sách Bảo Mật")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../assets/img/shape2.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"white"}},[_vm._v("Email: "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"href":"mailto:support@reboost.vn"}},[_vm._v("support@reboost.vn")]),_vm._v(" | Zalo: "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"target":"_blank","href":"https://zalo.me/+18135700346"}},[_vm._v("+18135700346")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"white"}},[_vm._v("© 2024 - Bản quyền thuộc về Công ty TNHH Reboost | "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"href":"/terms"}},[_vm._v("Điều Khoản")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"white","text-decoration":"underline"},attrs:{"href":"/privacy"}},[_vm._v("Chính Sách Bảo Mật")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }
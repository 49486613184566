
<template>
  <div id="terms-of-service">
    <h1 style="margin-bottom: 20px;"><a href="/" style="font-weight: 300">Reboost</a> Điều Khoản Dịch Vụ</h1>
    <p>Ngày có hiệu lực: 15/11/2023</p>
    <p>Reboost là một nền tảng trực tuyến giúp cho những người thi IELTS và TOEFL luyện tập và cải thiện kỹ năng viết của họ. Bằng cách truy cập vào trang web, ứng dụng di động hoặc bất kỳ dịch vụ nào được cung cấp (gọi chung là "Dịch Vụ"), bạn đồng ý rằng mình sẽ bị ràng buộc bởi các Điều Khoản Dịch Vụ này ("Điều Khoản"). Nếu bạn không đồng ý với tất cả các điều khoản và điều kiện, bạn không được phép sử dụng Dịch Vụ.</p>

    <h2>1. Dịch Vụ Được Cung Cấp</h2>
    <p>Reboost cung cấp đa dạng các chủ đề viết IETLTS và TOEFL với nhiều tài liệu hỗ trợ và bài mẫu cho từng chủ đề. Người dùng có thể luyện tập viết trên ứng dụng và gửi bài viết của mình để được chấm bởi các giáo viên của Reboost hoặc phần mềm đánh giá tự động. Sau khi hoàn thành 1 bài viết, người dùng có thể yêu cầu bài viết của mình được chấm và thanh toán trực tiếp trên hệ thống. Phản hồi cho bài viết sẽ được cung cấp trên hệ thống ngay sau khi thanh toán nếu người dùng chọn dịch vụ đánh giá tự động, hoặc trong vòng 28 giờ nếu người dùng chọn dịch vụ chấm bài từ giáo viên của Reboost.</p>

    <h2>2. Chính sách chấm bài và thời gian giao phản hồi</h2>
    <p>- Nếu bạn yêu cầu bài viết được chấm bởi giáo viên của Reboost, phản hồi cho bài viết sẽ được cung cấp trên hệ thống trong vòng 24 giờ. </p>
    <p>- Nếu bạn yêu cầu bài viết được chấm bởi hệ thống chấm bài tự động của Reboost, phản hồi cho bài viết sẽ được cung cấp trên hệ thống ngay sau khi bạn hoàn tất thanh toán.</p>

    <h2>3. Chính sách về sửa đổi, hủy, hoàn trả</h2>
    <p>
      Sau khi nhận được phản hồi cho bài viết của mình từ Reboost, bạn có 24 giờ để gửi khiếu lại về chất lượng của phản hồi mà bạn nhận được và yêu cầu bài viết được chấm lại hoặc hoàn tiền bằng cách liên hệ với chúng tôi tại <a href="mailto:support@reboost.vn">support@reboost.vn</a>. Yêu cầu chấm lại bài viết hay hoàn tiền của bạn sẽ được chúng tôi đánh giá trong vòng 2 ngày làm việc kể từ khi nhận dược yêu cầu. Nếu kết quả đánh giá cho thấy giáo viên hoặc hệ thống tự động của chúng tôi không cung cấp được phản hồi đáp ứng tiêu chuẩn đánh giá của Reboost, yêu cầu của bạn sẽ được duyệt. Nếu bạn yêu cầu bài viết được chấm lại, giáo viên của chúng tôi sẽ cung cấp lại phản hồi trong vòng 24h kể từ ngày yêu cầu chấm lại được duyệt. Nếu bạn yêu cầu hoàn tiền, chúng tôi sẽ hoàn tiền ngay sau khi yêu cầu được duyệt.
    </p>

    <h2>4. Chính sách thanh toán</h2>
    <p>- Phương thức thanh toán chấp nhận: thẻ ATM và tài khoản ngân hàng, ví điện tử ZaloPay, quét mã QR.</p>
    <p>- Thời gian xử lý thanh toán: Thanh toán sẽ được xử lý ngay lập tức.</p>

    <h2>5. Phí Chấm Bài</h2>
    <p>- 200,000 VNĐ cho 1 bài được chấm bởi giáo viên của Reboost.</p>
    <p>- 15,000 VNĐ cho 1 bài được chấm bởi hệ thống chấm bài tự động.</p>

    <h2>6. Tài Khoản Người Dùng</h2>
    <p>Để truy cập hầu hết các tính năng của Dịch Vụ, bạn phải đăng ký và tạo một tài khoản. Bạn có trách nhiệm bảo mật tài khoản và mật khẩu của mình và hạn chế quyền truy cập vào máy tính hoặc thiết bị của bạn. Bạn đồng ý chấp nhận trách nhiệm về tất cả các hoạt động diễn ra dưới tài khoản hoặc mật khẩu của mình.</p>

    <h2>7. Sử Dụng Dịch Vụ</h2>
    <p>Dịch Vụ chỉ dành cho mục đích cá nhân và không thương mại. Reboost cấp cho bạn một giấy phép hạn chế, không độc quyền, không thể chuyển nhượng và có thể thu hồi để sử dụng Dịch Vụ cho mục đích dự định tuân thủ các Điều Khoản này.</p>

    <h2>8. Hành Vi của Người Dùng</h2>
    <p>Bạn đồng ý không lạm dụng Dịch Vụ hoặc giúp người khác làm như vậy. Bạn không được cố gắng làm suy yếu an ninh hoặc tính toàn vẹn của các hệ thống máy tính hoặc mạng của Reboost hoặc bất kỳ hệ thống máy tính và mạng nào của người dùng.</p>

    <h2>9. Sở Hữu Trí Tuệ</h2>
    <p>Tất cả nội dung cung cấp trên Dịch Vụ, bao gồm văn bản, đồ họa, logo, hình ảnh, cũng như sự tổng hợp của chúng, và bất kỳ phần mềm nào được sử dụng trên trang web, là tài sản của Reboost hoặc các nhà cung cấp của nó và được bảo vệ bởi luật bản quyền và luật bản quyền quốc tế.</p>

    <h2>10. Từ Chối Bảo Đảm</h2>
    <p>Reboost không đảm bảo rằng Dịch Vụ sẽ đáp ứng đúng yêu cầu cụ thể của bạn hoặc sẽ có sẵn một cách liên tục, an toàn hoặc không lỗi. Phản hồi được cung cấp chỉ mang tính chất thông tin và không được coi là lời khuyên chuyên nghiệp.</p>

    <h2>11. Giới Hạn Trách Nhiệm</h2>
    <p>Reboost sẽ không chịu trách nhiệm về bất kỳ tổn thất gián tiếp, ngẫu nhiên, đặc biệt, hậu quả hoặc phạt đền, hoặc bất kỳ tổn thất lợi nhuận hoặc doanh thu, dù là trực tiếp hay gián tiếp, hoặc bất kỳ mất mát dữ liệu, sử dụng, thiện chí, hoặc các tổn thất vô hình khác.</p>

    <h2>12. Sửa Đổi Điều Khoản</h2>
    <p>Reboost có quyền, theo quyết định duy nhất của mình, sửa đổi hoặc thay thế các Điều Khoản này vào bất kỳ thời điểm nào. Bằng cách tiếp tục truy cập hoặc sử dụng Dịch Vụ của chúng tôi sau khi các sửa đổi đó trở nên có hiệu lực, bạn đồng ý bị ràng buộc bởi các điều khoản đã được sửa đổi.</p>

    <h2>13. Pháp Luật Điều Chỉnh</h2>
    <p>Các Điều Khoản này sẽ được điều chỉnh và giải thích theo luật pháp Việt Nam, không quan tâm đến các quy định về xung đột pháp luật của nó.</p>

    <h2>14. Thông Tin Liên Hệ</h2>
    <p>Đối với bất kỳ câu hỏi nào về các Điều Khoản này, vui lòng liên hệ với chúng tôi tại <a href="mailto:support@reboost.vn">support@reboost.vn</a>.</p>

  </div>
</template>
<script>
export default {
  name: 'Privacy',
  data() {
    return {
    }
  }
}
</script>
<style>
#terms-of-service {
  width: 900px;
  margin: auto;
  padding: 40px;
  background: #edeeef;
}
</style>


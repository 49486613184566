export const ApplicationDetail = {
  createAnAccount: 'Create an account',
  uploadCredential: 'Upload credentials',
  completeTraining: 'Complete training',
  startRating: 'Start rating',
  currentStatus: 'Current Status',
  appliedDate: 'Applied Date',
  firstName: 'First Name',
  firstNameRequired: 'First name is required',
  lastName: 'Last Name',
  lastNameRequired: 'Last name is required',
  email: 'Email',
  gender: 'Gender',
  genderRequired: 'Gender is required',
  pleaseSelectGender: 'Please select your gender',
  occupation: 'Occupation',
  occupationRequired: 'Occupation is required',
  pleaseSelectOccupation: 'Please input your occupation',
  firstLanguage: 'First Language',
  firstLanguageRequired: 'First Language is required',
  pleaseSelectFirstLanguage: 'Please select your first language',
  applyTo: 'Apply to Become',
  applyToRequired: 'Apply to is required',
  ieltsRater: 'IELTS Rater',
  toeflRater: 'TOEFL Rater',
  ieltsTestScores: 'IELTS Test Scores',
  ieltsTestScoresRequired: 'IELTS Test Scores is required',
  required: 'Required',
  writing: 'Writing',
  reading: 'Reading',
  listening: 'Listening',
  speaking: 'Speaking',
  ieltsCredentials: 'IELTS Credentials',
  ieltsCredentialsRequired: 'IELTS Credentials Photos is required',
  clickUpload: 'Click to upload',
  ieltsUploadTips: 'Please upload your IELTS test result, and any other supporting credidentials you may have. Files must be less than 500kb in size. Accept: jpp/jpeg, gif, png, m4v, mp4, avi, mpg ',
  toeflTestScores: 'TOEFL Test Scores',
  toeflTestScoresRequired: 'TOEFL Test Scores is required',
  toeflCredentials: 'TOEFL Credentials',
  toeflCredentialsRequired: 'TOEFL Credentials Photos is required',
  toeflUploadTips: 'Please upload your TOEFL test result, and any other supporting credidentials you may have. Files must be less than 500kb in size. Accept: jpp/jpeg, gif, png, m4v, mp4, avi, mpg',
  photoId: 'Photo ID',
  photoIdRequired: 'ID Photos is required',
  photoIdUploadTips: 'Please upload a form of photos identification such as ID card, driver license, or passport. The file must be less than 500kb in size. Accept: jpp/jpeg, gif, png, m4v, mp4, avi, mpg',
  biography: 'Biography',
  biographyPlaceholder: 'Tell us a little bit about yourself and the reason why you apply to become our rater',
  note: 'Note',
  notePlaceholder: 'Note',
  create: 'Create',
  cancel: 'Cancel',
  save: 'Save',
  approveTraining: 'Approve for training',
  reject: 'Reject',
  documentRequest: 'Document Request',
  viewIELTSTraining: 'View IELTS Training',
  viewTOEFLTraining: 'View TOEFL Training'
}

export const BoxesArea = {
  benefit: 'Benefits of being a Reboost member',
  title1: 'The diversity of writing topics',
  content1: "Our list of writing topics is getting bigger everyday with the help from our users all around the world. With Reboost, you won't ever be worry about running our of practice test.",
  title2: 'An Active Community',
  content2: 'Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna. Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt.',
  title3: 'Learning by Reviewing',
  content3: 'Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna. Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt.',
  title4: 'Constructive Feedback',
  content4: 'Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna. Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt.'
}

export const Submissions = {
  title: 'My Submissions',
  questionTable: 'Topic',
  statusTable: 'Status',
  timeSubmitTable: 'Time Submitted',
  timeTakenTable: 'Time Taken',
  actionsTable: 'Actions',
  viewReview: 'View Review',
  viewSubmission: 'View Submission'
}

export const ManageRaters = {
  raterApplication: 'Hồ sơ người phê bình',
  typeToSearch: 'Gõ để tìm kiếm',
  status: 'Trạng thái',
  resetFilters: 'đặt lại bộ lọc',
  applicationName: 'Tên người ứng tuyển',
  applicationDate: 'Ngày ứng tuyển',
  occupation: 'Nghề nghiệp',
  firstLanguage: 'Ngôn ngữ mẹ đẻ',
  operations: 'Hành động',
  view: 'Xem'
}


export const CONSTANT = {
  DISPUTE_STATUS: {
    'Open': 'Mở',
    'Accepted': 'Chập thuận',
    'Denied': 'Huỷ bỏ',
    'Refunded': 'Hoàn tiền',
    'Cancel': 'Hủy bỏ',
    'Approve': 'Chấp thuận',
    SUBMIT: 'Gửi đi',
    CLICK_TO_UPLOAD: 'Nhấn để tải lên',
    REVISE: 'Ôn lại',
    RATE_REVIEW: 'Đánh giá tủ lệ',
    DISPUTE: 'Tranh chấp'
  },
  RATER_STATUS: {
    'Applied': 'Đã ứng tuyển',
    'Approved for Training': 'Chập thuận đào tạo',
    'Document Requested': 'Yêu cầu thêm hồ sơ',
    'Document Submitted': 'Hồ sơ đã bổ sung',
    'Revision Requested': 'Yêu cầu làm lại',
    'Training Completed': 'Đào tạo hoàn tất',
    'Final Approval': 'Phê duyệt hoàn toàn',
    'Rejected': 'Từ chối',
    'Revision': 'Làm lại'
  },
  QUESTION_STATUS: {
    'Active': 'Active',
    'Contributed': 'Contributed',
    'Approved': 'Approved'
  }
}

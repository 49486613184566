export const ViewerToolBar = {
  fitToWidth: 'Chiều rộng',
  fitToPage: 'Chiều dài',
  approve: 'Chấp thuận',
  submit: 'Xác nhận',
  clickToUpload: 'Tải lên',
  revise: 'Ôn lại',
  rateReview: 'Đánh giá',
  dispute: 'Tranh chấp'
}

export const QuestionPreview = {
  title: 'Preview Question',
  question: 'Question',
  readingPassage: 'Reading Passage',
  goToListening: 'Go to listening',
  direction: 'Direction',
  directionSub: 'Give yourseft 3 minutes to read the passage.',
  headerPractice: 'LISTEN TO PART OF LECTURE ON THE SAME TOPIC',
  back: 'Back',
  audioScript: 'Audio Script'
}

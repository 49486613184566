export const ManageRaters = {
  raterApplication: 'Rater Applications',
  typeToSearch: 'Type to search',
  status: 'Status',
  resetFilters: 'reset all filters',
  applicationName: 'Application Name',
  applicationDate: 'Application Date',
  occupation: 'Occupation',
  firstLanguage: 'First Language',
  operations: 'Operations',
  view: 'View'
}

export const LandingHeader = {
    newMethod: 'How It Works',
    features: 'Features',
    pricing: 'Pricing',
    contact: 'Contact Us',
    becomeRater: 'Become a Rater',
    becomeRaterShort: 'For Raters',
    requestReviewShort: 'Request Review',
    requestReview: 'Request Review'
}

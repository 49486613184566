export const Disputes = {
  disputes: 'Bài tranh chấp',
  name: 'Tên',
  questionId: 'Id câu hỏi',
  status: 'Trạng thái',
  email: 'Email',
  userName: 'Tên người dùng',
  actions: 'Hành động',
  view: 'Xem',
  adminNote: 'Ghi chú của người quản trị'
}


export const ApplicationDetail = {
  createAnAccount: 'Tạo tài khoản',
  uploadCredential: 'Tải lên thông tin cá nhân',
  completeTraining: 'Hoàn thành đào tạo',
  startRating: 'Bắt đầu đánh giá',
  currentStatus: 'Trạng thái hiện tại',
  appliedDate: 'Ngày ứng tuyển',
  firstName: 'Tên',
  firstNameRequired: 'Tên là bắt buộc',
  lastName: 'Họ',
  lastNameRequired: 'Họ là bắt buộc',
  email: 'Email',
  gender: 'Giới tính',
  genderRequired: 'Giới tính là bắt buộc',
  pleaseSelectGender: 'Hãy chọn giới tính của bạn',
  occupation: 'Nghề nghiệp',
  occupationRequired: 'Nghề nghiệp là bắt buộc',
  pleaseSelectOccupation: 'Hãy nhập nghề nghiệp của bạn',
  firstLanguage: 'Ngôn ngữ mẹ đẻ',
  firstLanguageRequired: 'Ngôn ngữ mẹ đẻ là bắt buộc',
  pleaseSelectFirstLanguage: 'Hãy chọn ngôn ngữ mẹ đẻ',
  applyTo: 'Ứng tuyển vào vị trí',
  applyToRequired: 'Vị trí ứng tuyển là bắt buộc',
  ieltsRater: 'Người đánh giá IELTS',
  toeflRater: 'Người đánh giá TOEFL',
  ieltsTestScores: 'Điểm bài thi IELTS',
  ieltsTestScoresRequired: 'Điểm bài thi IELTS là bắt buộc',
  required: 'Bắt buộc',
  writing: 'Viết',
  reading: 'Đọc',
  listening: 'Nghe',
  speaking: 'Nói',
  ieltsCredentials: 'Chứng nhận IELTS',
  ieltsCredentialsRequired: 'Ảnh chứng nhận IELTS là bắt buộc',
  clickUpload: 'Tải lên',
  ieltsUploadTips: 'Hãy tải lên kết quả bài thi IELTS hoặc bất kì chứng nhận nào bạn có. Dung lượng ảnh phải dưới 500kb. File hợp lệ: jpp/jpeg, gif, png, m4v, mp4, avi, mpg',
  toeflTestScores: 'Điểm bài thi TOEFL',
  toeflTestScoresRequired: 'Điểm bài thi TOEFL là bắt buộc',
  toeflCredentials: 'Chứng nhận TOEFL',
  toeflCredentialsRequired: 'Ảnh chứng nhận TOEFL là bắt buộc',
  toeflUploadTips: 'Hãy tải lên kết quả bài thi TOEFL hoặc bất kì chứng nhận nào bạn có. Dung lượng ảnh phải dưới 500kb. File hợp lệ: jpp/jpeg, gif, png, m4v, mp4, avi, mpg',
  photoId: 'Ảnh cá nhân',
  photoIdRequired: 'Ảnh cá nhân là bắt buộc',
  photoIdUploadTips: 'Hãy tải lên mẫu các ảnh nhận dạng như Thẻ căn cước, bằng lái xe hoặc hộ chiếu. Dung lượng ảnh phải dưới 500kb. File hợp lệ: jpp/jpeg, gif, png, m4v, mp4, avi, mpg',
  biography: 'Lý lịch',
  biographyPlaceholder: 'Hãy chia sẻ một chút về bản thân và tại sao bạn ứng tuyển để trở thành người đánh giá của chúng tôi.',
  note: 'Ghi chú',
  notePlaceholder: 'Ghi chú',
  create: 'Tạo',
  cancel: 'Huỷ',
  save: 'Lưu',
  approveTraining: 'Phê duyệt đào tạo',
  reject: 'Từ chối',
  documentRequest: 'Yêu cầu thêm tài liệu',
  viewIELTSTraining: 'Xem bài đào tạo IELTS',
  viewTOEFLTraining: 'Xem bài đào tạo TOEFL'
}


export const HowItWork2 = {
  title: 'How It Works',
  description: 'At Reboost, we believe practice makes perfect. So we make every effort to encourage you to practice more and equip you with the best tools to make the most our of your practice.',
  titleBox1: 'Practice Writing Daily',
  contentBox1: 'Writing every day can help you become more aware of the limits of your vocabulary. The more you write, the more obvious that frequent word choices become. Once you know your limitations, you can expand your vocabulary by finding stronger words or experimenting with word order to see if there are other ways to get your message across. With our free, unlimited writing topics categorized into different writing tasks, you can practice writing using Reboost at your own pace.',
  titleBox2: 'Learn From Feedback',
  contentBox2: "At reboost, we believe that feedback is more important than scores and grades. Feedback lets you know how much progress you've made toward your writing goals and what else you need to do to meet them. Scores and grades only measure performance -- they don't tell you HOW to get better. Reboost gives you an opportunity to learn and improve your writing by reviewing constructive feedback given to you and providing useful feedback to others.",
  titleBox3: 'Revise Your Work',
  contentBox3: 'Studies have shown again and again that the best way to learn to write is to rewrite. In the revision process, you improve your reading skills and your analytical skills. You learn to challenge your own ideas, thus deepening and strengthening your argument. You will better absorb feedback given to you and learn to find the weaknesses in your writing. With Reboost, you are equipped with the best tools that make it easier for you to revise your writing effectively.'
}

export const Disputes = {
  disputes: 'Disputes',
  name: 'Name',
  questionId: 'Question Id',
  status: 'Status',
  email: 'Email',
  userName: 'Name',
  actions: 'Actions',
  view: 'View',
  adminNote: 'Admin Note'
}

export const HowItWork2 = {
  title: 'Một phương pháp học tập mới',
  description: 'Tại Reboost, chúng tôi tin rằng luyện tập tạo nên sự hoàn hảo. Vì vậy, chúng tôi mang lại những công cụ và tài liệu cần thiết để khuyến khích bạn luyện tập nhiều hơn, giúp bạn thu nhận được tối đa kiến thức và kỹ năng qua quá trình luyện tập của mình.',
  titleBox1: 'Luyện viết hàng ngày',
  // contentBox1: 'Viết mỗi ngày có thể giúp bạn nhận thức rõ hơn về giới hạn vốn từ vựng của mình. Bạn càng viết nhiều, các lựa chọn từ thường xuyên càng trở nên rõ ràng hơn. Khi bạn biết những hạn chế của mình, bạn có thể mở rộng vốn từ vựng của mình bằng cách tìm những từ mạnh hơn hoặc thử nghiệm với thứ tự từ để xem có cách nào khác để truyền tải thông điệp của bạn hay không. Với đa dạng chủ đề viết và các tài nguyên hữu dụng trên Reboost, bạn có thể luyện tập kỹ năng viết mỗi ngày, theo tốc độ riêng của bạn.',
  contentBox1: 'Việc viết hàng ngày giúp bạn hiểu rõ về hạn chế từ vựng của mình. Qua thời gian, bạn sẽ có thể đưa ra lựa chọn từ ngữ phù hợp hơn cho bài viết của mình. Nhận thức được những giới hạn này cho phép bạn mở rộng từ vựng bằng cách tìm kiếm từ mới và cấu trúc câu mới để cải thiện cách truyền đạt. Với đa dạng chủ đề và nguồn tài nguyên phong phú, Reboost sẽ hỗ trợ và khuyến khích bạn rèn luyện kỹ năng viết mỗi ngày theo nhịp độ cá nhân.',
  titleBox2: 'Học hỏi từ phản hồi',
  // contentBox2: 'Ở reboost, chúng tôi tin rằng phản hồi quan trọng hơn điểm số. Phản hồi cho bạn biết bạn đã đạt được bao nhiêu tiến bộ đối với mục tiêu viết của mình và bạn cần làm gì khác để đạt được mục tiêu đó. Điểm số và điểm số chỉ đo lường hiệu suất - chúng không cho bạn biết làm thế nào để trở nên tốt hơn. Reboost mang đến cho bạn cơ hội học hỏi và cải thiện bài viết của mình bằng cách xem xét những phản hồi mang tính xây dựng dành cho bạn và cung cấp những phản hồi hữu ích cho người khác.',
  contentBox2: 'Tại Reboost, chúng tôi coi trọng phản hồi hơn là điểm số. Phản hồi giúp bạn biết mình đang ở đâu, hỗ trợ bạn đặt ra mục tiêu rõ ràng để tiến bộ, và cho bạn biết cần phải làm gì để đạt được mục tiêu đó. Điểm số chỉ phản ánh hiệu suất mà không hướng dẫn bạn cách cải thiện. Reboost cung cấp cơ hội để bạn học và nâng cao kỹ năng viết thông qua việc nhận và cung cấp phản hồi mang tính xây dựng, giúp bạn và cả cộng đồng cùng phát triển.',
  titleBox3: 'Chỉnh sửa lại bài viết',
  // contentBox3: 'Các nghiên cứu đã chỉ ra rằng cách tốt nhất để học viết là viết lại. Trong quá trình ôn tập, bạn cải thiện kỹ năng đọc và kỹ năng phân tích của mình. Bạn học cách thách thức những ý tưởng của chính mình, do đó đào sâu và củng cố lập luận của mình. Bạn sẽ tiếp thu tốt hơn những phản hồi được đưa ra cho bạn và học cách tìm ra những điểm yếu trong bài viết của bạn. Với Reboost, bạn được trang bị những công cụ tốt nhất giúp bạn dễ dàng sửa đổi bài viết của mình một cách hiệu quả.'
  contentBox3: 'Các nghiên cứu cho thấy việc chỉnh sửa lại bài viết của mình là phương pháp học viết hiệu quả nhất. Qua quá trình này, bạn không chỉ nâng cao kỹ năng đọc và phân tích, mà còn học được cách phát triển và phản biện ý tưởng của bản thân để củng cố lập luận của mình. Tiếp nhận phản hồi giúp bạn nhận diện và khắc phục điểm yếu trong bài viết. Reboost cung cấp công cụ hỗ trợ bạn tự đánh giá và chỉnh sửa bài viết một cách thuận lợi và hiệu quả nhất.'
}

export const LandingHeader = {
    newMethod: 'Cách Học Mới',
    features: 'Chức Năng',
    pricing: 'Bảng Giá',
    contact: 'Liên Hệ',
    becomeRater: 'Trở Thành Giáo Viên',
    becomeRaterShort: 'Giáo Viên',
    requestReviewShort: 'Chấm Bài',
    requestReview: 'Yêu Cầu Chấm Bài'

}

import reviewService from '@/services/review.service.js'

const getDefaultState = () => {
  return {
    annotations: {},
    reviews: [],
    reviewsById: [],
    anno: null,
    requests: []
  }
}

const state = getDefaultState()

const actions = {
  loadReviews({ commit }, obj) {
    return reviewService.getAllReviews().then(result => {
      commit('SET_REVIEWS', result)
    })
  },
  loadReviewsById({ commit }, obj) {
    return reviewService.getReviewsById().then(result => {
      commit('SET_REVIEWS_BY_ID', result)
    })
  },
  loadReviewAnnotation({ commit }, obj) {
    return reviewService.getAnnotation(obj.docId, obj.reviewId).then(result => {
      commit('SET_REVIEW_ANNOTATIONS', result)
    })
  },
  addReviewAnnotation({ commit }, obj) {
    return reviewService.addAnnotation(obj).then(rs => {
      commit('ADD_REVIEW_ANNOTATION', rs)
    })
  },
  addInTextComment({ commit }, docId, reviewId, obj) {
    return reviewService.addInTextComment(docId, reviewId, obj).then(rs => {
      commit('ADD_REVIEW_ANNOTATION', rs)
    })
  },
  loadRequests({ commit }) {
    return reviewService.getReviewRequestModel().then(result => {
      commit('SET_REQUESTS', result)
    })
  },
  clearState({ commit }) {
    commit('CLEAR_STATE')
  }
}

const mutations = {
  SET_REVIEWS: (state, reviews) => {
    state.reviews = reviews
  },
  SET_REVIEWS_BY_ID: (state, reviews) => {
    state.reviewsById = reviews
  },
  SET_REVIEW_ANNOTATIONS: (state, annotation) => {
    state.annotations = annotation
  },
  ADD_REVIEW_ANNOTATION: (state, annotation) => {
    state.anno = annotation
    state.annotations['annotations'].push(annotation)
  },
  SET_REQUESTS: (state, requests) => {
    state.requests = requests
  },
  CLEAR_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}

const getters = {
  getAnnotations: state => state.annotations,
  getAddedAnnotation: state => state.anno,
  getReviews: state => state.reviews,
  getReviewsById: state => state.reviewsById,
  getAllRequests: state => state.requests
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

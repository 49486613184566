export const AdminArticles = {
  title: 'All Articles',
  textSearch: 'Type to search by name',
  category: 'Category',
  type: 'Type',
  status: 'Status',
  resetFilter: 'Reset all filters',
  addArticle: 'Add new article',
  articleTitle: 'Title',
  articleCategory: 'Category',
  articleLabel: 'Label',
  articleAuthor: 'Author',
  articlePostedDate: 'Posted Date',
  articleOriginalUrl: 'Original Url',
  articleQuestion: 'Question',
  articleRelate: 'Related Article',
  articleAction: 'Action'
}


export const FAQ = {
  title: 'Các câu hỏi thường gặp',
  title1: 'Có bao nhiêu múi giờ trong tất cả?',
  value1: 'Cung cấp một ngày 24 giờ và kinh độ 360 độ xung quanh Trái đất',
  title2: 'Một ngày và năm trên sao Kim là bao lâu?',
  value2: 'Sao Kim mất 224,7 ngày Trái đất để hoàn thành một quỹ đạo quanh Mặt trời.',
  title3: 'Con vật gì có mùi như bỏng ngô?',
  value3: 'Binturongs có mùi thơm như bỏng ngô.',
  titleContact: 'Đặt câu hỏi của bạn'
}

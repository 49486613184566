export const FAQ = {
  title: 'Frequently Asked Questions',
  title1: 'How many time zones are there in all?',
  value1: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
  title2: 'How long is a day and year on Venus?',
  value2: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
  title3: 'What animal smells like popcorn?',
  value3: 'Binturongs smell like popcorn.',
  titleContact: 'Ask Your Question'
}

export const CONSTANT = {
  DISPUTE_STATUS: {
    'Open': 'Open',
    'Accepted': 'Accepted',
    'Denied': 'Denied',
    'Refunded': 'Refunded',
    'Cancel': 'Cancel',
    'Approve': 'Approve',
    'Submit': 'Submit',
    CLICK_TO_UPLOAD: 'Click to upload',
    'Revise': 'Revise',
    RATE_REVIEW: 'Rate Review',
    'Dispute': 'Dispute'
  },
  RATER_STATUS: {
    'Applied': 'Applied',
    'Approved for Training': 'Approved for Training',
    'Document Requested': 'Document Requested',
    'Document Submitted': 'Document Submitted',
    'Revision Requested': 'Revision Requested',
    'Training Completed': 'Training Completed',
    'Final Approval': 'Final Approval',
    'Rejected': 'Rejected',
    'Revision': 'Revision'
  },
  QUESTION_STATUS: {
    'Active': 'Active',
    'Contributed': 'Contributed',
    'Approved': 'Approved'
  }
}

export const RaterRegister = {
  firstName: 'Tên',
  firstNameRequired: 'Tên là bắt buộc',
  lastName: 'Họ',
  lastNameRequired: 'Họ là bắt buộc',
  usernameEmail: 'Email',
  usernameRequired: 'Email là bắt buộc',
  password: 'Mật khẩu',
  passwordRequired: 'Mật khẩu là bắt buộc',
  createAccount: 'Tạo tài khoản người đánh giá',
  alreadyHave: 'Bạn đã có tài khoản?',
  signInNow: 'Đăng nhập ngay',
  orSignInWith: 'Hoặc đăng nhập với',
  byLoggingIn: 'Khi đăng nhập, tôi đồng ý với',
  terms: 'các điều kiện',
  and: 'và',
  policies: 'chính sách'
}


export const WhyChooseUs = {
  title: 'Why Choose Us',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  titleBox1: 'Unlimited Practice Tests',
  contentBox1: "Unlike other sites that asks you to pay for having access to practice tests, Reboost's test repository will always be available for you, for free.",
  titleBox2: 'Free Peer Evaluations',
  contentBox2: "At Reboost, requesting for peer review will always be free. We only ask that you give feedback to someone else's writing before making your request.",
  titleBox3: 'Effective Study Methods',
  contentBox3: 'With Reboost, you can learn from our collection of tips and guides, from feedback you receive or provide, and from revising your work.',
  titleBox4: 'Verified Pro Raters',
  contentBox4: 'If you need quick, constructive feedback for your writing, our verified professional raters are always available to help.'
}

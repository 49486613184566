export const Banner = {
  slogan: 'A new way to improve your IELTS & TOEFL writing score',
  bannerContentDescription: 'Reboost c is your premier platform for elevating language writing test scores! No matter which test you take, count on us for comprehensive preparation and assured score enhancement, with:',
  content1: 'Unlimited free practice tests',
  content2: 'Valuable, constructive feedback for your improvement',
  content3: 'Learning through giving and receiving feedback',
  content4: 'Engaging community fostering consistent practice',
  emailAddress: 'Email address',
  placeholderEmail: 'Enter email address',
  password: 'Password',
  placeholderPassword: 'Create a password',
  signIn: 'Sign In',
  signInOther: 'Or you can sign in with',
  and: 'and',
  byLogging: 'By logging in, I agree to the',
  terms: 'terms',
  policies: 'policies',
  noAccount: "Don't have an account yet?",
  signUp: 'Sign Up',
  forgotPassword: 'Forgot Password'
}

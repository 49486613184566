export const Register = {
  firstName: 'First Name',
  firstNameRequired: 'First name is required',
  lastName: 'Last Name',
  lastNameRequired: 'Last name is required',
  usernameEmail: 'Email',
  usernameRequired: 'Email is required',
  password: 'Password',
  passwordRequired: 'Password is required',
  createAccount: 'Sign Up',
  alreadyHave: 'Already have an account?',
  signInNow: 'Sign In Now',
  orSignInWith: 'Or you can sign in with',
  bySigningUp: 'By signing up, you agree with our',
  terms: 'terms',
  and: 'and',
  policies: 'policies'
}


export const Samples = {
  samples: 'Samples',
  id: 'Id',
  question: 'Question',
  bandScore: 'Band Score',
  comment: 'Comment',
  text: 'Text',
  action: 'Action',
  approve: 'Approve'
}

<template>
  <el-dialog
    id="previewQuestionDialog"
    :title="messageTranslates('questionPreview', 'title')"
    :before-close="handleClose"
    :visible.sync="dialogVisible"
    width="60%"
  >
    <div id="questionPreview" style="height: 100%;display: flex; flex-direction: column">
      <div style="margin-bottom: 8px;">
        <el-row>
          <el-col>
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div class="title-tab">1. {{ getDataQuestion.title }}</div>
                <div style="display: flex;">
                  <div style="font-size: 13px; color: #44ce6f; margin-right: 25px;">{{ getDataQuestion.test }} {{ getDataQuestion.section }}</div>
                  <div style="display: flex;  margin-right: 20px;">
                    <img class="img-icon" src="../../assets/img/like.png" alt="">
                    <label for="" style="font-weight: 200; font-size: 13px;">{{ getDataQuestion.like }}</label>
                  </div>
                  <div style="display: flex;  margin-right: 20px;">
                    <img class="img-icon" src="../../assets/img/dislike.png" alt="">
                    <label for="" style="font-weight: 200; font-size: 13px;">{{ getDataQuestion.dislike }}</label>
                  </div>
                </div>
              </div>
            </div>
            <hr style="margin: 0; margin-bottom: 8px;">
            <div class="tip" style=" margin-bottom: 8px;">
              <pre style="font-size: 13px; color: #6084a4;"> <span style="font-weight: 600;">Direction: </span> <span v-if="hideDirection == 'Hide'" v-html="getDataQuestion.direction" /> <span class="hide-direction" @click="toggleDirection()">{{ hideDirection }}</span></pre>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="flex-grow: 1;position: relative;">
        <div class="par-content default">
          <el-row style="margin-bottom: 8px;">
            <el-col class="question-con">
              <div style="font-size: 13px;">
                <span style="font-weight: 600;word-break: keep-all;">{{ messageTranslates('questionPreview', 'question') }}: </span>
                <div id="questionContent" v-html="getQuestion.content" />
              </div>
            </el-col>
          </el-row>
          <el-row v-if="isShowQuestion">
            <div v-if="!isShowListeningTab && getReading != ''">
              <div class="header-practice" style="margin-bottom: 8px; display: flex;">
                <div style="flex-grow: 1; display: flex; align-items: center;">
                  {{ messageTranslates('questionPreview', 'readingPassage') }}
                </div>
                <div v-if="!isShowTimer">
                  <el-button size="mini" @click="toggleBtnShowTab()">{{ messageTranslates('questionPreview', 'goToListening') }}</el-button>
                </div>
              </div>
              <div v-if="!closeTimer" class="body-practice" style="margin-bottom: 8px;">
                <div class="tip" style="display: flex; align-items: center; justify-content: space-between;">
                  <div style="flex-grow: 1;">
                    <pre style="font-size: 13px; color: #6084a4;"> <span style="font-weight: 600;">{{ messageTranslates('questionPreview', 'direction') }}: </span>{{ messageTranslates('questionPreview', 'directionSub') }}</pre>
                  </div>
                  <div v-if="getListening != ''" style="margin-left: 14px;">
                    <div v-if="isShowTimer && !closeTimer">
                      {{ minute }} : {{ second }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-col v-if="getReading =='' && getListening != '' || isShowListeningTab || getReading == ''" :span="24">
              <div v-if="isShowListeningTab" style="width: 100%;">
                <div class="header-practice" style="display:flex; justify-content: space-between;margin-bottom: 8px; ">
                  <div style="display: flex; align-items: center;">
                    {{ messageTranslates('questionPreview', 'headerPractice') }}
                  </div>
                  <div v-if="getReading != ''">
                    <el-button size="mini" @click="backClick()">{{ messageTranslates('questionPreview', 'back') }}</el-button>
                  </div>
                </div>
                <hr style="margin:0; margin-bottom: 8px; ">
                <div>
                  <audio v-if="getListening != ''" controls style="width: 100%; height: 35px; margin-bottom: 3px;">
                    <source :src="'/audio/'+getListening" type="audio/mpeg">
                  </audio>
                  <div class="script-select" style="border: 2px solid #eff0f2; display: flex; padding: 5px 10px;" @click="toggleBtnShowScript">
                    <div style="flex-grow: 1;">
                      <i class="el-icon-document-copy" />
                      {{ messageTranslates('questionPreview', 'audioScript') }}
                    </div>
                    <div :class="{'rotate-icon' : isShowScript}">
                      <i class="fas fa-caret-down" />
                    </div>
                  </div>

                </div>
              </div>
            </el-col>
          </el-row>
          <div v-if="isShowQuestion && getReading != '' && !isShowListeningTab && isShowReading" style="margin: 0;">
            <pre id="readingContent" v-html="getReading.content" />
          </div>
          <div v-if="isShowQuestion && isShowScript && isShowListeningTab" class="body-transcript" style="margin: 0;">
            <pre id="transcriptContent" v-html="getTranscript.content" />
          </div>
          <div v-if="isShowQuestion && (isShowChart || (getReading == '' && getChart != ''))">
            <img :src="'/photo/' + getChart" :alt="getChart" style="max-height: 100%; max-width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>

export default {
  name: 'QuestionPreview',
  data() {
    return {
      isShowQuestion: true,
      isShowListeningTab: false,
      isShowChart: false,
      isShowScript: false,
      dialogVisible: false,
      loadCompleted: false,
      hideDirection: 'Hide',
      isShowTimer: false,
      closeTimer: false,
      isShowReading: true
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/getUser']
    },
    getDataQuestion() {
      var data = this.$store.getters['question/getSelected']
      if (data.direction) {
        data.direction = data.direction.trim()

        if (data.direction.substr(data.direction.length - 1) == '\n') {
          data.direction = data.direction.substring(0, data.direction.length - 1)
        }
      }
      return data
    },
    getDataQuestionParts() {
      return this.$store.getters['question/getSelected']['questionsPart']
    },
    getReading() {
      if (typeof (this.getDataQuestionParts) != 'undefined') {
        if (this.getDataQuestionParts.find(u => u.name == 'Reading')) {
          return this.getDataQuestionParts.find(u => u.name == 'Reading')
        }
      }
      return ''
    },
    getListening() {
      if (typeof (this.getDataQuestionParts) != 'undefined') {
        if (this.getDataQuestionParts.find(u => u.name == 'Listening')) {
          return this.getDataQuestionParts.find(u => u.name == 'Listening').content
        }
        return 'audio_sample.mp3'
      }
      return ''
    },
    getQuestion() {
      if (typeof (this.getDataQuestionParts) != 'undefined') {
        if (this.getDataQuestionParts.find(u => u.name == 'Question')) {
          return this.getDataQuestionParts.find(u => u.name == 'Question')
        }
      }
      return ''
    },
    getChart() {
      if (typeof (this.getDataQuestionParts) != 'undefined') {
        if (this.getDataQuestionParts.find(u => u.name == 'Chart')) {
          var chart = this.getDataQuestionParts.find(u => u.name == 'Chart').content
          // chart.content = '/' + chart.content.trim()
          return chart
        }
      }
      return ''
    },
    getTranscript() {
      if (typeof (this.getDataQuestionParts) != 'undefined') {
        if (this.getDataQuestionParts.find(u => u.name == 'Transcript')) {
          return this.getDataQuestionParts.find(u => u.name == 'Transcript')
        }
      }
      return ''
    }
  },
  async mounted() {

  },
  destroyed() {
  },
  methods: {
    showDialog(question) {
      this.$store.dispatch('question/loadQuestion', +question.id).then(rs => {
        this.loadCompleted = true
        this.dialogVisible = true

        console.log(this.getDataQuestionParts)
      })
    },
    toggleBtnShowTab() {
      this.isShowTimer = true
      this.isShowReading = false
      this.timeSpent = 0
      this.isShowReading = false
      this.isShowTimer = false

      if (this.getListening != '') {
        this.isShowListeningTab = true
      }
      if (this.getChart != '') {
        this.isShowChart = true
      }
    },
    toggleBtnShowScript() {
      this.isShowScript = !this.isShowScript
    },
    toggleDirection() {
      if (this.hideDirection == 'Hide') { this.hideDirection = 'Show' } else {
        this.hideDirection = 'Hide'
      }
    },
    backClick() {
      this.isShowListeningTab = false
      this.isShowReading = true
    },
    handleClose() {
      this.$store.dispatch('question/clearSelectedQuestion')
      this.isShowQuestion = true
      this.isShowListeningTab = false
      this.isShowChart = false
      this.isShowScript = false
      this.dialogVisible = false
      this.loadCompleted = false
      this.hideDirection = 'Hide'
      this.isShowTimer = false
      this.closeTimer = false
      this.isShowReading = true
      this.dialogVisible = false
    }
  }
}
</script>
<style>
#previewQuestionDialog .el-dialog{
    margin-top: 10vh !important;
}
.hide-direction {
  font-weight: bold;
  color: #409EFF;
}
.hide-direction:hover{
  cursor: pointer;
}
.el-tabs--border-card {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.el-tabs__content {
  flex-grow: 1 !important;
}

.el-tab-pane {
  height: 100%;
  min-height: 0 !important;
}
#practiceWritingCheckoutContainer .el-dialog .el-dialog__header{
  padding: 10px 0 0 0 !important;
  height: 60px;
}
#practiceWritingCheckoutContainer .el-dialog .el-dialog__body{
  padding: 0 !important;
}
#questionContent{
  padding: 10px;
  word-break: break-word;
}
#questionContent p {
  color: black;
  line-height: 1.5 !important;
}
#readingContent {
  word-break: break-word;
}
#transcriptContent p {
  font-size: 14px;
  text-align: justify;
  white-space: break-spaces;
  font-family: inherit !important;
  margin-bottom: 0 !important;
  color: black;
}
</style>
<style scoped>
#questionPreview{
    min-height: 500px;
}
.payment-method-title{
  display: flex;
  align-items: center;
}
.dialog-body{
  padding: 10px 0 10px 0;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgb(187, 187, 187);
}

.par-content {
  position: absolute;
  /* padding-right: 10px; */
  top: 0;
  left: 0;
  /* overflow-y: scroll; */
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

/* .par-content::-webkit-scrollbar {
  width: 7px;
} */

/* Handle */
.par-content::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 4px;
}

/* Handle on hover */
.par-content::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.padding-10 {
  padding: 0px;
}
.default{
  padding: 0;
  margin: 0;
}

.question-con {
  padding: 5px 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  border-radius: 4px;
}

.header-passage {
  font-size: 13px;
  background-color: #f5f7fa;
  border: 2px solid #eff0f2;
}

.body-passage {
  border-bottom: 2px solid #eff0f2;
  border-left: 2px solid #eff0f2;
  border-right: 2px solid #eff0f2;
}

.header-practice {
  font-size: 14px;
  font-weight: 600;
  color: rgb(23, 56, 82);
}

.body-practice {
  padding: 0;
}

.body-transcript {
  margin-top: 0;
  padding: 10px;
  border: 1px solid #e2e2e2;
}

.btn-start {
  background-color: #e8e8e8;
  width: 90px;
  height: 30px;
  padding: 0;
  border-radius: 0;
}

.footer-end {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  margin-bottom: 40px;
}

.title-tab {
  font-size: 14px;
  font-weight: 600;
  color: rgb(23, 56, 82);
}

.img-icon {
  height: 17px;
  width: 20px;
  padding-right: 5px;
}

.tip {
  padding: 5px 10px;
  font-size: 12px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
}

.script-select:hover {
  cursor: pointer;
  background-color: #f5f7fa;
}

.el-tab-pane {
  min-height: 650px;
}

.splitpanes--vertical>.splitpanes__splitter {
  width: 55px;
  background: linear-gradient(90deg, #ccc, #111);
}

pre {
  font-size: 14px;
  text-align: justify;
  white-space: break-spaces;
  font-family: inherit !important;
  margin-bottom: 0 !important;
}

.rotate-icon {
  transform: rotateZ(180deg);
}

.textarea-style {
  width: 100%;
  padding: 15px;
  border: 1px solid #e2e2e2;
  border-top: none;
  resize: none;
  outline: none;
  height: 100%;
}
.submited-message {
  width: calc(100% - 210px);
}

</style>


export const Login = {
  usernameEmail: 'Địa chỉ email của bạn',
  password: 'Mật khẩu',
  signIn: 'Đăng nhập',
  forgotPassword: 'Quên mật khẩu?',
  signUp: 'Đăng ký tài khoản',
  orSignIn: 'Hoặc đăng nhập với',
  byLoggingIn: 'Khi đăng nhập, tôi đồng ý với',
  terms: 'các điều kiện',
  and: 'và',
  policies: 'chính sách'
}


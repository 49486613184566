export const WhyChooseUs = {
  title: 'Tại sao chọn chúng tôi',
  description: 'Bản thân nỗi đau có thể là đau rất nhiều, nhưng lý do chính là đau, nhưng tôi cho nó thời gian để rơi vào đó như một nỗi đau và nỗi đau lớn nào đó.',
  titleBox1: 'Bài kiểm tra thực hành không giới hạn',
  contentBox1: 'Không giống như các trang web khác yêu cầu bạn trả tiền để có quyền truy cập vào các bài kiểm tra thực hành, kho bài kiểm tra của Reboost sẽ luôn có sẵn miễn phí cho bạn.',
  titleBox2: 'Đánh giá đồng nghiệp miễn phí',
  contentBox2: 'Tại Reboost, yêu cầu đánh giá ngang hàng sẽ luôn miễn phí. Chúng tôi chỉ yêu cầu bạn cung cấp phản hồi cho bài viết của người khác trước khi đưa ra yêu cầu của bạn.',
  titleBox3: 'Phương pháp học tập hiệu quả',
  contentBox3: 'Với Reboost, bạn có thể học hỏi từ bộ sưu tập các mẹo và hướng dẫn của chúng tôi, từ phản hồi bạn nhận được hoặc cung cấp và từ việc sửa đổi công việc của bạn.',
  titleBox4: 'Người xếp hạng chuyên nghiệp đã được xác minh',
  contentBox4: 'Nếu bạn cần phản hồi nhanh chóng, mang tính xây dựng cho bài viết của mình, những người đánh giá chuyên nghiệp đã được xác minh của chúng tôi luôn sẵn sàng trợ giúp.'
}

export const Review = {
  title: 'My Reviews',
  questionTable: 'Question',
  testSectionTable: 'Test Section',
  questionTypeTable: 'Question Type',
  statusTypeTable: 'Status Type',
  lastActivityTable: 'Last Activity Date',
  actionsTable: 'Actions',
  makeNew: 'Make New Review',
  completePending: 'Complete Pending Review'
}

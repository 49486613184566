<template>
  <!-- Start Boxes Area -->
  <div id="benefit" class="boxes-area">
    <div class="container">
      <div class="section-title" style="text-align: center; margin-bottom: 30px;">
        <h3> Lợi ích khi trở thành giáo viên của Reboost</h3>
        <div class="bar" />
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-box">
            <div class="icon">
              <i class="flaticon-money" />
            </div>
            <h3>Kiếm thêm thu nhập</h3>
            <p>Reboost giúp giáo viên kiếm thêm thu nhập bằng cách chấm bài viết, hỗ trợ học viên từ xa, linh hoạt về thời gian và không gian, mở ra cơ hội phát triển chuyên môn và tài chính.</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-box bg-f78acb">
            <div class="icon">
              <i class="flaticon-data-1" />
            </div>
            <h3>Lịch trình linh hoạt</h3>
            <p>Thời gian làm việc linh hoạt cho phép giáo viên tự do sắp xếp lịch trình, giúp tối đa hóa cơ hội kiếm thêm thu nhập mà không làm ảnh hưởng đến công việc chính.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-box bg-c679e3">
            <div class="icon">
              <i class="flaticon-dashboard" />
            </div>
            <h3>Cơ hội phát triển bản thân</h3>
            <p>Cơ hội phát triển bản thân qua việc chia sẻ kiến thức, hỗ trợ học viên tiến bộ. Môi trường linh hoạt, đa dạng giúp bạn nâng cao kỹ năng và kinh nghiệm giảng dạy.
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-box bg-eb6b3d">
            <div class="icon">
              <i class="flaticon-support" />
            </div>
            <h3>Tạo ra sự khác biệt</h3>
            <p> Giáo viên của Reboost tạo ra sự khác biệt thực sự trong hành trình học tập của học viên. Bằng cách tận tâm hướng dẫn, bạn sẽ là nguồn cảm hứng giúp họ tiến bộ.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- End Boxes Area -->
</template>

<script>
export default {
  name: 'BoxesArea'
}
</script>

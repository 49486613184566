export const AddArticle = {
  addNewArticle: 'Add New Article',
  title: 'Title',
  titlePlaceholder: 'Please input article title',
  category: 'Category',
  label: 'Label',
  author: 'Author',
  postedDate: 'Posted Date',
  originalUrl: 'Original Url',
  originalUrlPlaceholder: 'Please input original url',
  question: 'Question',
  relatedArticles: 'Related Articles'
}

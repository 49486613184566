export const Submissions = {
  title: 'Bài nộp của tôi',
  questionTable: 'Câu hỏi',
  statusTable: 'Trạng thái',
  timeSubmitTable: 'Thời gian nộp',
  timeTakenTable: 'Thời gian làm',
  actionsTable: 'Hành động',
  viewReview: 'Xem đánh giá',
  viewSubmission: 'Xem bài nộp'
}


<template>
  <div id="privacy-policy">
    <h1 style="margin-bottom: 20px;"><a href="/" style="font-weight: 300">Reboost</a> Chính Sách Bảo Mật</h1>
    <p>Ngày có hiệu lực: 15/11/2023</p>
    <p>Chính sách Bảo Mật này mô tả cách Reboost thu thập, sử dụng và bảo vệ thông tin cá nhân của bạn khi bạn sử dụng trang web https://reboost.vn (gọi là "Trang") và các dịch vụ được cung cấp bởi Reboost (gọi là "Dịch Vụ"). Khi sử dụng Trang và Dịch Vụ của chúng tôi, bạn đồng ý với các điều khoản được nêu trong chính sách này.</p>

    <h3>Thông Tin Chúng Tôi Thu Thập</h3>

    <h5>1. Thông Tin Tài Khoản Người Dùng</h5>
    <p>Khi bạn tạo một tài khoản trên Reboost, chúng tôi thu thập các thông tin sau:</p>
    <ul>
      <li>Họ và Tên</li>
      <li>Số Điện Thoại</li>
      <li>Địa Chỉ Email</li>
      <li>Mật Khẩu (được mã hóa)</li>
    </ul>

    <h5>2. Dữ Liệu Sử Dụng</h5>
    <p>Chúng tôi tự động thu thập thông tin về cách bạn tương tác với Trang và Dịch Vụ của chúng tôi, bao gồm:</p>
    <ul>
      <li>Dữ liệu nhật ký (địa chỉ IP, loại trình duyệt, các trang đã truy cập)</li>
      <li>Thông tin thiết bị (loại thiết bị, hệ điều hành)</li>
    </ul>

    <h5>3. Dữ Liệu Luyện Viết</h5>
    <p>Là một nền tảng tập trung vào việc cải thiện kỹ năng viết tiếng Anh, chúng tôi thu thập các văn bản bạn gửi để luyện tập và phản hồi được cung cấp bởi hệ thống của chúng tôi.</p>

    <h3>Cách Chúng Tôi Sử Dụng Thông Tin Của Bạn</h3>
    <p>Chúng tôi sử dụng thông tin đã thu thập cho các mục đích sau:</p>
    <ul>
      <li>Để cung cấp và cải thiện Dịch Vụ của chúng tôi</li>
      <li>Để cá nhân hóa trải nghiệm của bạn</li>
      <li>Để liên lạc với bạn (ví dụ, cập nhật, phản hồi)</li>
    </ul>

    <h3>Bảo Mật Dữ Liệu</h3>
    <p>Chúng tôi coi trọng việc bảo mật thông tin cá nhân của bạn. Reboost thực hiện các biện pháp để bảo vệ dữ liệu khách hàng khỏi việc truy cập không được phép, thay đổi, tiết lộ hoặc phá hủy.</p>
    <p>Chính sách bảo mật dữ liệu của chúng tôi nhằm đảm bảo tính bảo mật, toàn vẹn và sẵn có của thông tin cá nhân khách hàng được lưu trữ trong cơ sở dữ liệu Azure SQL bằng cách sử dụng mã hóa và các biện pháp bảo mật khác.</p>

    <h3>Chia Sẻ Thông Tin Của Bạn</h3>
    <p>Chúng tôi không bán, trao đổi hoặc chuyển giao thông tin cá nhân của bạn cho bên thứ ba mà không có sự đồng ý của bạn, trừ như được nêu trong Chính Sách Bảo Mật này hoặc như được yêu cầu bởi pháp luật.</p>

    <h3>Sự Lựa Chọn của Bạn</h3>
    <p>Bạn có thể quản lý sở thích tài khoản và từ chối nhận một số thông tin liên lạc. Tuy nhiên, một số thông tin liên lạc cần thiết liên quan đến Dịch Vụ có thể là bắt buộc.</p>

    <h3>Sử Dụng Cookies</h3>
    <p>Reboost có thể sử dụng cookies để nâng cao trải nghiệm của bạn. Bạn có thể quản lý sở thích cookies thông qua cài đặt trình duyệt của mình.</p>

    <h3>Thay Đổi Chính Sách Bảo Mật</h3>
    <p>Reboost có quyền cập nhật Chính Sách Bảo Mật này. Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng chính sách mới trên trang này.</p>

    <h3>Yêu Cầu Xóa Dữ Liệu</h3>
    <p>Bạn có quyền yêu cầu xóa dữ liệu cá nhân của mình. Để yêu cầu xóa dữ liệu, vui lòng gửi email đến địa chỉ <a href="mailto:support@reboost.vn">support@reboost.vn</a>. Chúng tôi sẽ xử lý yêu cầu của bạn trong thời gian sớm nhất.</p>

    <h3>Liên Hệ Chúng Tôi</h3>
    <p>Nếu bạn có bất kỳ câu hỏi hoặc lo ngại nào về Chính Sách Bảo Mật này, vui lòng liên hệ với chúng tôi tại <a href="mailto:support@reboost.vn">support@reboost.vn</a></p>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
  data() {
    return {
    }
  }
}
</script>
<style>
#privacy-policy {
  width: 900px;
  margin: auto;
  padding: 40px;
  background: #edeeef;
}
</style>


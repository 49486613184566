export const Questions = {
  allQuestion: 'All Questions',
  typeToSearch: 'Type to search by name',
  testSection: 'Test Section',
  type: 'Type',
  status: 'Status',
  resetFilter: 'Reset all filters',
  addQuestion: 'Add new question',
  questionName: 'Question Name',
  questionType: 'Question Type',
  createdBy: 'Created by',
  addedDate: 'Added Date',
  action: 'Action',
  publish: 'Publish',
  edit: 'Edit',
  preview: 'Preview',
  addNewSample: 'Add New Sample',
  delete: 'Delete'
}

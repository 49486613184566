export const Review = {
  title: 'Đánh giá của tôi',
  questionTable: 'Câu hỏi',
  testSectionTable: 'Phần kiểm tra',
  questionTypeTable: 'Loại câu hỏi',
  statusTypeTable: 'Loại trạng thái',
  lastActivityTable: 'Ngày hoạt động gần nhất',
  actionsTable: 'Hành động',
  makeNew: 'Cung cấp đánh giá',
  completePending: 'Hoàn thành bài đánh giá đang chờ xử lý'
}

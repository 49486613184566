
<template>
  <div id="data-deletion">
    <h1 style="margin-bottom: 20px;">Delete Your Data Instructions</h1>
    <p>Thank you for using our app. If you wish to delete your data, you can follow the instructions below:</p>
    <p>
      - Send an email to our support team at <a href="mailto:reboost.ad@gmail.com">reboost.ad@gmail.com</a> requesting your data to be deleted
      - Our support team will delete your data permanently and send you a notification email when it is completed.
    </p>
    <p>If you encounter any issues or have questions regarding data deletion, please contact our support team at <a href="mailto:reboost.ad@gmail.com">reboost.ad@gmail.com</a>.</p>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
  data() {
    return {
    }
  }
}
</script>
<style>
#data-deletion {
  width: 900px;
  margin: auto;
  padding: 40px;
  background: #edeeef;
}
</style>


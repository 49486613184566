export const Login = {
  usernameEmail: 'Email',
  password: 'Password',
  signIn: 'Sign In',
  forgotPassword: 'Forgot Password?',
  signUp: 'Sign Up',
  orSignIn: 'Or you can sign in with',
  byLoggingIn: 'By logging in, I agree to the',
  terms: 'terms',
  and: 'and',
  policies: 'policies'
}

export const Question = {
  title: 'All Writing Topics',
  completed: 'Completed',
  pickOne: 'Pick One',
  resetAll: 'Reset Filters',
  placeholderSearch: 'Search questions',
  testSection: 'Test Section',
  type: 'Type',
  status: 'Status',
  titleTable: 'Topic',
  testSectionTable: 'Test Section',
  typeTable: 'Type',
  sampleTable: 'Sample',
  statusTable: 'Status'
}

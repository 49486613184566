export const ViewerToolBar = {
  fitToWidth: 'Fit to width',
  fitToPage: 'Fit to page',
  approve: 'Approve',
  submit: 'Submit',
  clickToUpload: 'Click to upload',
  revise: 'Revise',
  rateReview: 'Submit Rating',
  dispute: 'Dispute'
}

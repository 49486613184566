export const ContactDialog = {
  title: 'Liên hệ hỗ trợ',
  fullName: 'Tên',
  email: 'Email',
  reason: 'Lý do',
  userRole: 'Vai trò người dùng',
  message: 'Tin nhắn',
  uploadFile: 'Tải tệp lên',
  clickToUpload: 'Tải lên',
  submit: 'Xác nhận',
  cancel: 'Huỷ'
}

export const BoxesArea = {
  benefit: 'Lợi ích khi tham gia luyện tập cùng Reboost',
  title1: 'Đa dạng chủ đề viết đã được phân loại',
  content1: 'Reboost cung cấp miễn phí cho tất cả thành viên bộ chủ đề viết đa dạng đã được phân loại theo mức độ khó, giúp các bạn dễ dàng lựa chọn chủ để viết phù hợp với năng lực của mình.',
  title2: 'Tài nguyên hữu dụng cho từng chủ đề',
  content2: 'Cho mỗi chủ đề, Reboost cung cấp ý tưởng phát triển bài viết, gợi ý cách sắp xếp, từ vựng liên quan, và bài văn mẫu cho mỗi band score cùng với đánh giá điểm mạnh, điểm yếu cho từng bài.',
  title3: 'Học bằng cách cung cấp đánh giá',
  content3: 'Việc cung cấp đánh giá giúp bạn nhận ra điểm mạnh và yếu trong bài của mình, từ đó có hướng cải thiện. quá trình này còn tạo điều kiện cho bạn học hỏi các kỹ thuật và cách thức diễn đạt ý tưởng mới.',
  title4: 'Phản hồi nhanh, chi tiết, và chất lượng',
  content4: 'Hệ thống chấm bài tự động và các giáo viên với nhiều năm kinh nghiệm giảng dạy sẽ cung cấp cho bạn phản hồi tốt nhất, giúp bạn hiểu được vấn đề trong bài viết của mình để từ đó sớm cải thiện.'
}

export const Question = {
  title: 'Tất cả các chủ đề viết',
  completed: 'Hoàn thành',
  pickOne: 'Ngẫu nhiên',
  resetAll: 'Đặt lại tất cả bộ lọc',
  placeholderSearch: 'Nhập để tìm kiếm',
  testSection: 'Phần kiểm tra',
  type: 'Loại',
  status: 'Trạng thái',
  titleTable: 'Tiêu đề',
  testSectionTable: 'Phần kiểm tra',
  typeTable: 'Loại',
  sampleTable: 'Mẫu',
  statusTable: 'Trạng thái'
}

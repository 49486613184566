export const QuestionPreview = {
  title: 'Xem trước câu hỏi',
  question: 'Câu hỏi',
  readingPassage: 'Đọc đoạn văn',
  goToListening: 'Đi đến Listening',
  direction: 'Phương hướng',
  directionSub: 'Hãy dành cho mình 3 phút để đọc đoạn văn.',
  headerPractice: 'LẮNG NGHE MỘT PHẦN CỦA BÀI GIẢNG VỀ CÙNG MỘT CHỦ ĐỀ',
  back: 'Trở về',
  audioScript: 'Script Âm thanh'
}

export const ContactDialog = {
  title: 'Customer Support Request',
  fullName: 'Full Name',
  email: 'Email Address',
  reason: 'Reason',
  userRole: 'User Role',
  message: 'Message',
  uploadFile: 'Upload File',
  clickToUpload: 'Click to upload',
  submit: 'Submit',
  cancel: 'Cancel'
}
